<template>
  <div>
    <div class="px-2 mb-2 d-flex justify-content-between align-items-center">
      <h1 class="mb-0">Caixa</h1>

      <div class="d-flex justify-content-end align-items-center mb-0" cols="8">
        <b-card class="control-border-color shadow-none mb-0 mr-2">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0 mr-2">Conta do financiador</h4>
            <v-select
              v-model="selectedFinancier"
              :options="financiers"
              :clearable="false"
              class="assignor-selector d-flex flex-column"
              label="nome"
            >
            </v-select>
          </div>
        </b-card>
        <b-card class="control-border-color shadow-none mb-0">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0 mr-2">Saldo atual</h4>
            <h3 class="mb-0 text-saldo">{{ financier.saldo | moneyFormat }}</h3>
          </div>
        </b-card>
      </div>
    </div>
    <b-card>
      <div>
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h3 class="mb-0">Caixa</h3>
          <div class="d-flex align-items-center">
            <b-button
              @click="downloadCashMovementsXlsx()"
              variant="flat-secondary"
              class="text-nowrap mr-2"
              :disabled="!this.financier.id"
            >
              Exportar
              <img
                class="img-icon text-secondary ml-50"
                height="17"
                width="16"
                src="@/assets/images/export_icon.svg"
              />
            </b-button>
            <feather-icon
              icon="FilterIcon"
              size="24"
              class="cursor-pointer text-secondary mr-2"
              @click="showFiltersButton = true"
            />

            <b-button-group class="mr-2">
              <b-button
                :variant="selectedEntryTypeVariant.abertura"
                @click="selectButton('abertura')"
              >
                <span class="text-dark font-weight-bolder"> Abertura </span>
              </b-button>
              <b-button
                :variant="selectedEntryTypeVariant.entrada"
                @click="selectButton('entrada')"
              >
                <span class="text-dark font-weight-bolder"> Entrada </span>
              </b-button>
              <b-button :variant="selectedEntryTypeVariant.saida" @click="selectButton('saida')">
                <span class="text-dark font-weight-bolder"> Saída </span>
              </b-button>
            </b-button-group>

            <b-button
              variant="outline-warning"
              class="mr-1"
              @click="$bvModal.show('newOpeningModal')"
              :disabled="!financier.id"
            >
              <span class="text-dark font-weight-bolder"> Abertura de caixa </span>
            </b-button>

            <b-button
              variant="success"
              class="bg-light-success"
              @click="$bvModal.show('newEntryModal')"
              :disabled="!financier.id"
            >
              <span class="text-dark font-weight-bolder"> Novo lançamento </span>
            </b-button>
          </div>
        </div>
        <b-table
          id="my-table"
          ref="ccDatatable"
          outlined
          responsive
          class="position-relative text-center"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
        >
          <template #cell(valor)="data">
            <span
              :class="{
                'entry-in': data.item.tipo === 'entrada',
                'entry-out': data.item.tipo === 'saida',
                opening: data.item.tipo === 'abertura',
              }"
              >{{ data.item.valor | moneyFormat }}</span
            >
          </template>
        </b-table>

        <div>
          <b-row class="mt-2">
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <div class="d-flex align-items-center">
                <label class="mb-0 mr-1">Itens por página</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-flex flex-column mr-1"
                />
              </div>
              <span class="text-muted"> {{ showing }} </span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                aria-controls="my-table"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <b-modal id="newEntryModal" centered hide-footer header-bg-variant="white">
      <div class="d-flex flex-column justify-content-center align-items-start p-1">
        <h3 class="mb-1 text-dark">Novo lançamento</h3>
        <validation-observer ref="resetForm" #default="{ invalid }" class="w-100">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Tipo" label-for="tipo">
                <validation-provider #default="{ errors }" name="Tipo" vid="tipo" rules="required">
                  <v-select
                    v-model="entry.type"
                    :options="statuses"
                    :clearable="false"
                    class="w-100 d-flex flex-column mr-2"
                    :reduce="(option) => option.label"
                  >
                    <template #option="option">
                      <div>
                        <span :class="option.class"> {{ option.label }} </span>
                      </div>
                    </template>
                    <template #selected-option="option">
                      <div>
                        <span :class="option.class"> {{ option.label }} </span>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Valor" label-for="valor">
                <validation-provider
                  #default="{ errors }"
                  name="Valor"
                  vid="valor"
                  rules="required"
                >
                  <money
                    id="limit"
                    v-model="entry.value"
                    class="form-control"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    v-bind="money"
                    name="limit"
                    min="0"
                    :maxlength="16"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Descrição" label-for="descricao">
                <validation-provider
                  #default="{ errors }"
                  name="Descrição"
                  vid="descricao"
                  rules="required"
                >
                  <b-form-input
                    id="descricao"
                    v-model="entry.description"
                    :state="errors.length > 0 ? false : null"
                    name="descricao"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <div class="d-flex">
                <p class="mb-0">Fundo de investimento:</p>
                <p class="mb-0 ml-1 text-dark font-weight-bolder">{{ financier.nome }}</p>
              </div>
            </b-col>
          </b-row>

          <div class="d-flex flex-row justify-content-around mt-2">
            <b-button
              variant="outline-secondary"
              class="mb-1 mr-1 text-dark font-weight-bolder"
              @click="$bvModal.hide('newEntryModal')"
            >
              Cancelar
            </b-button>
            <b-button
              variant="secondary"
              class="mb-1 bg-light-secondary font-weight-bolder"
              :disabled="invalid"
              @click="newEntryConfirm"
            >
              Registrar lançamento
            </b-button>
          </div>
        </validation-observer>
      </div>
    </b-modal>

    <b-modal id="newOpeningModal" centered hide-footer header-bg-variant="white">
      <div class="d-flex flex-column justify-content-center align-items-start p-1">
        <h3 class="mb-1 text-dark">Nova abertura de caixa</h3>
        <validation-observer ref="resetForm" #default="{ invalid }" class="w-100">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Valor" label-for="valor">
                <validation-provider
                  #default="{ errors }"
                  name="Valor"
                  vid="valor"
                  rules="required"
                >
                  <money
                    id="limit"
                    v-model="entry.value"
                    class="form-control"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    v-bind="money"
                    name="limit"
                    min="0"
                    :maxlength="16"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Descrição" label-for="descricao">
                <validation-provider
                  #default="{ errors }"
                  name="Descrição"
                  vid="descricao"
                  rules="required"
                >
                  <b-form-input
                    id="descricao"
                    v-model="entry.description"
                    :state="errors.length > 0 ? false : null"
                    name="descricao"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <div class="d-flex">
                <p class="mb-0">Fundo de investimento:</p>
                <p class="mb-0 ml-1 text-dark font-weight-bolder">{{ financier.nome }}</p>
              </div>
            </b-col>
          </b-row>

          <div class="d-flex flex-row justify-content-around mt-2">
            <b-button
              variant="outline-secondary"
              class="mb-1 mr-1 text-dark font-weight-bolder"
              @click="$bvModal.hide('newOpeningModal')"
            >
              Cancelar
            </b-button>
            <b-button
              variant="secondary"
              class="mb-1 bg-light-secondary font-weight-bolder"
              :disabled="invalid"
              @click="newOpeningConfirm"
            >
              Registrar abertura
            </b-button>
          </div>
        </validation-observer>
      </div>
    </b-modal>

    <datatable-filters-handler-sidebar
      :is-task-handler-sidebar-active="showFiltersButton"
      @closeSideBar="showFiltersButton = false"
      @updateFilterValues="updateFilterValues"
      :filters="customFilters"
    />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BTab,
  BTabs,
  BCard,
  BModal,
  BTable,
  BBadge,
  BButton,
  BTooltip,
  BFormGroup,
  BFormInput,
  BPagination,
  BButtonGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import DatatableTabs from '@/views/common/crud/components/DatatableTabs'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  moneyFormatDatatable,
  brazilianFormattedDateHour,
} from '@core/comp-functions/data_visualization/datatable'
import { required } from '@validations'
import DatatableFiltersHandlerSidebar from '@/views/common/crud/components/DatatableFiltersHandlerSidebar.vue'
import downloadFile from '@/utils/download-file'

export default {
  name: 'Concessoes',
  components: {
    BCol,
    BRow,
    BTab,
    BTabs,
    BCard,
    BTable,
    BModal,
    BBadge,
    BButton,
    vSelect,
    BTooltip,
    flatPickr,
    BFormGroup,
    BFormInput,
    BPagination,
    BButtonGroup,
    DatatableTabs,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    DatatableFiltersHandlerSidebar,
  },
  filters: {
    moneyFormat: moneyFormatDatatable,
    brazilianFormattedDateHour,
  },
  data() {
    return {
      datatableFilterValues: {},
      showFiltersButton: false,
      selectedEntryType: '',
      currentPage: 1,
      items: [],
      fields: [
        {
          label: 'Data/Hora',
          key: 'created_at',
          sortable: false,
          formatter: brazilianFormattedDateHour,
        },
        {
          label: 'Valor',
          key: 'valor',
          sortable: false,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Descrição',
          key: 'descricao',
          sortable: false,
        },
        {
          label: 'Usuário',
          key: 'usuario.nome',
          sortable: false,
        },
      ],
      perPage: 10,
      perPageOptions: [10, 25, 50],
      totalRows: 0,
      financier: {},
      selectedDate: '',
      selectedStatus: null,
      selectedFinancier: null,
      financiers: [],
      statuses: [
        { label: 'entrada', class: 'entry-in' },
        { label: 'saida', class: 'entry-out' },
      ],
      config: {
        mode: 'range',
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      money: {
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      entry: {
        type: 'entrada',
        value: '',
        description: '',
      },
      customFilters: [
        {
          category: 'Por parâmetro',
          fields: [
            {
              label: 'Data inicio',
              key: 'data_inicio',
              type: 'date',
              options: {
                locale: Portuguese,
                dateFormat: 'd/m/Y',
              },
            },
            {
              label: 'Data fim',
              key: 'data_fim',
              type: 'date',
              options: {
                locale: Portuguese,
                dateFormat: 'd/m/Y',
              },
            },
          ],
        },
      ],
    }
  },
  mounted() {
    this.getFinanciers()
  },
  methods: {
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    selectButton(type) {
      if (this.selectedEntryType === type) {
        this.selectedEntryType = ''
        return
      }

      this.selectedEntryType = type
    },
    async getFinancier() {
      const payload = {
        id: this.selectedFinancier.id,
      }
      const data = await this.$store.dispatch('admin/getFinancier', payload)
      this.financier = data
    },
    async getEntries() {
      if (!this.selectedFinancier?.id) return

      let payload = {
        id: this.selectedFinancier.id,
        ...this.params,
      }

      const data = await this.$store.dispatch('admin/getEntries', payload)

      this.items = data.data
      this.perPage = data.per_page
      this.totalRows = data.total
      this.currentPage = data.current_page
    },
    async getFinanciers() {
      const data = await this.$store.dispatch('admin/getFinanciers')
      this.financiers = data
    },
    newEntryConfirm() {
      this.$swal({
        title: 'Confirmar lançamento?',
        text: 'O lançamento será registrado na conta do financiador.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Registrar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#289f75',
        cancelButtonColor: '#fc065e',
      }).then((result) => {
        if (result.value) {
          this.newEntry()
        }
      })
    },
    async newEntry() {
      const payload = {
        valor: this.entry.value,
        descricao: this.entry.description,
        tipo: this.entry.type,
        id: this.financier.id,
      }
      await this.$store.dispatch('admin/newEntry', payload)
      this.$swal({
        title: 'Lançamento registrado!',
        text: 'O lançamento foi registrado com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#289f75',
      })
      this.entry = {
        type: 'entrada',
        value: '',
        description: '',
      }
      this.getFinancier()
      this.getEntries()
      this.$bvModal.hide('newEntryModal')
    },
    async downloadCashMovementsXlsx() {
      try {
        if (!this.selectedFinancier?.id) return

        let payload = {
          id: this.selectedFinancier.id,
          ...this.params,
        }

        const { data } = await this.$store.dispatch('admin/exportCashMovements', payload)
        downloadFile(data, 'movimentos_caixa.xlsx', 'text/xlsx')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Ocorreu um erro ao tentar baixa o arquivo.',
        })
      }
    },
    newOpeningConfirm() {
      this.$swal({
        title: 'Confirmar abertura?',
        text: 'A abertura será registrada na conta do financiador.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Registrar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#289f75',
        cancelButtonColor: '#fc065e',
      }).then((result) => {
        if (result.value) {
          this.newOpening()
        }
      })
    },
    async newOpening() {
      const payload = {
        valor: this.entry.value,
        descricao: this.entry.description,
        tipo: 'abertura',
        id: this.financier.id,
      }
      await this.$store.dispatch('admin/newOpening', payload)
      this.$swal({
        title: 'Abertura registrada!',
        text: 'A abertura foi registrada com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#289f75',
      })
      this.entry = {
        type: 'abertura',
        value: '',
        description: '',
      }
      this.getFinancier()
      this.getEntries()
      this.$bvModal.hide('newOpeningModal')
    },
  },
  computed: {
    params() {
      let params = {
        per_page: this.perPage,
        page: this.currentPage,
        tipo: this.selectedEntryType,
      }

      if (Object.keys(this.datatableFilterValues).length) {
        params = { ...params, ...this.datatableFilterValues }
      }

      return params
    },
    selectedEntryTypeVariant() {
      const types = {
        abertura: 'outline-success',
        entrada: 'outline-success',
        saida: 'outline-success',
      }

      types[this.selectedEntryType] = 'success'

      return types
    },
    showing() {
      let start = this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.perPage + 1
      let end = start === 1 ? this.perPage : start + this.perPage - 1
      if (end > this.totalRows) end = this.totalRows
      if (this.totalRows === 0) start = 0

      return `Mostrando de ${start} a ${end} de ${this.totalRows} registros`
    },
  },
  watch: {
    selectedEntryType(newValue) {
      // TODO - filter by type
    },
    selectedFinancier(newValue) {
      if (newValue) {
        this.entry.fund_id = newValue.id
        this.getFinancier()
        this.getEntries()
      }
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentPage = 1
          this.skipCurrentPage = false
        }
      },
      immediate: true,
    },
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.getEntries()
    },
  },
}
</script>

<style>
.assignor-selector {
  min-width: 250px;
}

.entry-in {
  color: #289f75;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  word-wrap: break-word;
  text-transform: uppercase;
}

.entry-out {
  color: #fc065e;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  word-wrap: break-word;
  text-transform: uppercase;
}

.opening {
  color: #c97a4d;
  font-size: 16px;
  font-family: Lato;
  font-weight: 700;
  word-wrap: break-word;
  text-transform: uppercase;
}

.text-saldo {
  color: #289f75;
  font-size: 21px;
  font-family: Lato;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: 0.14px;
  word-wrap: break-word;
}

.absolute-with-tabs {
  position: absolute;
  width: 100%;
  padding-right: 3rem;
}

/* pegar uma UL de uma DIV de class tabs */
.custom-tabs-header ul {
  position: absolute;
}
</style>
